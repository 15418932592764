.tags {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
}

.tag {
    flex-shrink: 0;
    align-self: center;
}

.container {
    display: flex;
    flex-direction: column;
    height: 100%;
}


.content {
    display: flex;
    flex-direction: column;
    flex: 1;
    flex-grow: 1;
    overflow: auto;
    padding: 0 32px;
}