.double_modal {
    width: 100%;
    height: 100%;
    display: flex;
}

.double_modal > div:first-child {
    height: 100%;
    background-color: var(--color-bg-primary);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 488px;
    flex: 1;
    /* padding: 0 var(--gap-2xl) var(--gap-xl); */
    box-sizing: border-box;
    overflow: auto;
    position: relative;
}

.double_modal > div:last-child:not(:only-child)  {
    width: 352px;
    height: 100%;
    background-color: var(--color-bg-secondary);
    box-sizing: border-box;
    padding: var(--gap-xl);
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.double_modal > div:last-child:not(:only-child) > * {
    flex-shrink: 0;
}

.header_wrapper {
    position: sticky;
    top: 0;
    z-index: 20;
    margin: 0 -32px;
}

.image_skeleton {
    height: 200px;
    border-radius: 8px;
    margin-bottom: 12px;
    width: 100%;
}

.image_skeleton::before {
    background-color: var(--color-bg-primary) !important;
}

.info_skeleton {
    height: 124px;
    border-radius: 8px;
    width: 100%;
}

.info_skeleton::before {
    background-color: var(--color-bg-primary) !important;
}
 
@media screen and (max-width: 823px) {
    .double_modal {
        overflow: auto;
        display: block;
    }
    
    .double_modal > div:first-child {
        width: 100%;
        height: auto;
    }
    
    .double_modal > div:last-child:not(:only-child) {
        width: 100%;
        height: auto;
    }
}