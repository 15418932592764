.footer {
  width: 100%;
  flex: 1;
}

.card {
  height: 100%;
}
.description {
  height: 64px;
}