.wrap {
    border-radius: 4px 4px 0 0 !important;
    overflow: hidden !important;
}

.editor {
    border: 0 !important;
    border-radius: 0 !important;
    margin: 0 0 0 0 !important;
    line-height: 24px !important;
    border-bottom: 1px solid var(--color-border-primary) !important;
}

.editor p {
    padding: 0 0 8px;
}

.editor > div {
    padding: 12px 4px !important;
    background: var(--color-bg-secondary) !important;
} 

.editor > div:hover {
    background: var(--color-bg-thirdly) !important;
}

.editor > * {
    line-height: 26px !important;
    font-size: 14px !important; 
}

.toolbar {
    border: 0 !important;
    padding: 12px 16px !important;
    background: var(--color-bg-secondary) !important;
    border-bottom: 1px solid var(--color-border-primary) !important;
}

.action {
    width: auto !important;
    min-width: auto !important;
    height: 26px !important;
    padding: 0 12px !important;
    border-radius: 4px !important;
    font-size: 13px !important;
    line-height: 16px !important;
    background: var(--color-bg-thirdly) !important;
}
