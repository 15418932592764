.slot_schedule {
    height: 100%;
    left: 4px;
    right: 4px;
    border: 0;
    border-radius: 4px;
    position: absolute;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
}

.slot_schedule:hover {
    transform: scale(1.02);
}

.slot_schedule svg {
    position: absolute;
    right: 6px;
    top: 6px;
    color: #1c1919;
    width: 20px;
}
