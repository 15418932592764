.error {

}

.img {
  width: 100% !important;
  height: 120px !important;
}

.blur img {
  -webkit-filter: grayscale(100%) blur(3px); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%) blur(2px);
}

@keyframes shake {
    0% {
      transform: translate(-3px, 0);
    }
    25% {
      transform: translate(3px, 0);
    }
    75% {
      transform: translate(-3px, 0);
    }
    100% {
      transform: translate(0px, 0);
    }
  }
  
.shake {
    animation: shake 0.2s ease-in-out 0s 2;
}