.divider {
    width: 100%
}

.user {
    padding: 8px 0 4px;
    display: flex;
    align-items: center;
    gap: 8px;
}
