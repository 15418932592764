.innerInput {
    background-color: var(--color-bg-primary) !important;
    border-bottom: 1px solid var(--color-border-primary) !important;
    min-height: 40px !important;
    margin: 0px -9px !important;
}

.innerInput:focus {
    background-color: red !important;
}

.innerInput::before{
    display: none !important;
}