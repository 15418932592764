.description {
    overflow: hidden;
    text-overflow: ellipsis;
    height: 62px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.children {
    height: 40px;
}

.img > div {
    background-color: transparent !important;
}