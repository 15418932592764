.img:not(:first-child), .alt {
    margin-left: -15px;
    z-index: 1;
}

.text {
    text-align: left;
}

.wrapper {
    padding-right: 20px;
    display: flex;
}

.option {
    padding: 6px 0;
}

.input {
    background: var(--color-bg-primary) !important;
}
.input:before {
    border: 0 !important;
}