
.calendar_header {
    padding: 16px;
    position: relative;
}

.content {
    padding: 16px;
    flex: 1;
    box-sizing: border-box;
    overflow: auto;
}

.content__loading {
    opacity: 0.4;
    pointer-events: none;
    overflow: unset;
}

