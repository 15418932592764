.empty_component {
    width: 100%;
    min-height: 64px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-text-secondary);
    border: 1px dashed var(--color-border-primary);
}

.empty_component svg {
    margin-right: 12px;
}
