.content {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-top: 16px;
}

.flex {
    flex: 1;
}
