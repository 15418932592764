.container {
    height: 100% !important;
    display: flex !important;
    background-color:  var(--color-bg-primary) !important;
    border-radius: 4px !important;
    align-items: start !important;
    padding: 6px !important;
    box-shadow: 0px 3px 17px 0px rgba(37, 37, 39, 0.08) !important;
}

.time {
    text-align: center;
}