.container {
    max-width: 600px;
    margin: 0 auto;
    padding: 30px 0 80px 0;
    overflow: auto;
    width: 100%;
}

.mobile {
    padding: 20px;
    box-sizing: border-box;
}
 
