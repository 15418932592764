.calendar {
    position: relative;
    width: 100%;
    min-height: 700px;
    flex: 1;
    display: flex;
}

.container {
    height: 100%;
}

.form {
    width: 440px;
}

.menu {
    position: absolute !important;
    top: 4px;
    left: 4px;
    z-index: 9;
}