.resourcesWrap {
    display: flex;
    align-items: center;
    color: var(--color-bg-primary);
    margin-bottom: 20px;
    position: sticky;
    z-index: 10;
    top: 0;
}

.emptyState {
    position: absolute;
    top: 74px;
    left: 0;
    right: 0;
}

.drag {
    position: absolute;
    left: -19px;
    opacity: 0;

    & button {
        cursor: grab !important;
        &:active {
            cursor: grabbing !important;
        }
    }
}

.resource:hover .drag {
    opacity: 1;
}

.title {
    white-space: nowrap;
    max-width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.resource {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 0 16px;
    gap: 12px;
    border: none;
    outline: none;
    /* background-color: var(--color-bg-secondary); */
}

.resource img {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    opacity: 0.8;
}

.dots_btn {
    margin-left: 8px !important;
}

.time {
    display: block;
    width: 50px;
    height: 5px;
    position: relative;
    text-align: center;
}

.time_button {
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    color: var(--color-graphic-thirdly);
    /* background-color: var(--color-bg-secondary); */
    border-radius: 8px 0 0 8px;
}
