.item_wrapper {
    padding: 16px 0;
    width: 100%;
}

.button {
    width: 100%;
    background: none;
    border: none;
    cursor: pointer;
}

.title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    text-align: left;
}

.description {
    margin-top: 8px;
    text-align: left;
    width: 100%;
    display: block;
}

.input_wrap {
    padding-left: 32px;
}

.input {
    margin-top: 8px;
    text-align: left;
}

/* .selected {
    border: 1px solid var(--color-border-accent);
} */
