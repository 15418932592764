.form_wrapper {
    width: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
}

.content_wrapper {
    padding-left: 32px;
    max-width: 424px;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
}
