.secondary.menu_button {
    height: 26px;

    &:hover {
        background-color: transparent;
        & span {
            color: var(--color-text-main) !important;
        }
    }
}

.menu_button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
    text-decoration: none;

    padding: 0 8px;
    height: 32px;
    border-radius: 6px;
    z-index: 10;

    color: var(--color-text-primary);
    white-space: nowrap;
    font-weight: 500;
    transition: all 0.15s ease-in-out;

    & span {
        font-size: 13px !important;
    }

    & svg {
        color: var(--color-graphic-primary);
        width: 20px;
    }

    &.active, &:hover, &.expanded {
        background-color: var(--color-bg-primary);

        & svg {
            color: var(--color-graphic-primary);
        }
    }

    &.expanded .chevron {
        transform: rotate(180deg)
    }

    &.secondary {
        & span {
            font-weight: 400 !important;   
        }
    }

    &.active.secondary {
        background-color: transparent;
        & span {
            color: var(--color-text-main) !important;
        }
    }
}

.leftSide, .rightAddons {
    display: flex;
    align-items: center;
    gap: 12px;
}

.collapseWrapper {
    padding-left: 32px;
}
