.component {
    background: var(--color-glass-footer);
    z-index: 80;
    padding: 16px 0;
    position: sticky;
    bottom: 0;
    -webkit-backdrop-filter: saturate(200%) blur(2px);
    backdrop-filter: saturate(200%) blur(2px);
}

