/* .active svg {
    animation: bellshake 8s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite;
    backface-visibility: hidden;
    transform-origin: top center;
} */

.button {
    background-color: var(--color-bg-secondary);
    border-radius: 12px !important;
    height: 40px !important;
    overflow: hidden;
    min-width: 40px !important;
}

.progress {
    position: absolute;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: var(--color-bg-positive);
}
.progressWrap {
    position: absolute;
    height: 2px;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--color-border-primary);
}

.active {
    position: relative;
}

.active::after {
    content: '';
    position: absolute;
    background: var(--color-bg-negative);
    width: 10px;
    border: 5px solid var(--color-bg-primary);
    height: 10px;
    right: -7px;
    top: -6px;
    border-radius: 50%;
}

@keyframes bellshake {
    0% {
        transform: rotate(0);
    }
    1% {
        transform: rotate(8deg);
    }
    2% {
        transform: rotate(-8deg);
    }
    3% {
        transform: rotate(6deg);
    }
    4% {
        transform: rotate(-6deg);
    }
    5% {
        transform: rotate(4deg);
    }
    6% {
        transform: rotate(-4deg);
    }
    7% {
        transform: rotate(2deg);
    }
    8% {
        transform: rotate(0);
    }
}
