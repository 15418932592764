.done {
    background-color: #E9F5F3;
}

.done svg {
    color: var(--color-graphic-positive);
}

.container {
    display: flex;
    text-decoration: none;
    color: inherit;
    flex-direction: column;
}

