.container {
    padding: 12px !important;
    border-radius: 8px !important;
    text-align: left !important;
    border: 1px solid  var(--color-bg-secondary);
    box-sizing: border-box;
}

.container.active {
    border-color: var(--color-bg-accent) !important;
}

.user {
    padding: 8px 0 4px;
    display: flex;
    align-items: center;
    gap: 12px;
}
