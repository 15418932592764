.slot_wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    padding: 8px 12px 8px 8px;
    height: 100%;
    box-sizing: border-box;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    position: relative;
    background-color: var(--color-bg-primary);
    overflow: hidden;
    box-shadow: inset 0 0 0 1px var(--color-border-special-component);

    &.compact {
        height: 40px;
    }
}

.content {
    display: flex;
    align-items: center;
    overflow: hidden;
    margin-right: 16px;
}


.addon_wrapper {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
}


.red {
    background-color: var(--color-bg-negative-light);

    & .icon {
        background-color: var(--color-bg-negative);
    }

}

.white {
    background-color: var(--color-bg-special-big-objects);

    & svg {
        color: var(--color-graphic-secondary) !important;
    }    
}

.blue {
    background-color: var(--color-bg-accent-light);

    & .icon {
        background-color: var(--color-bg-accent);
    }
}

.grey {
    background-color: var(--color-bg-quaternary);

    & .icon {
        background-color: #0e0e0e;
    }
}

.orange {
    background-color: var(--color-bg-attention-light);

    & .icon {
        background-color: var(--color-bg-attention);
    }
}

.purple {
    background-color: var(--color-bg-indigo-light);
 
    & .icon {
        background-color: var(--color-bg-indigo);
    }
}

.green {
    background-color: var(--color-bg-positive-light);

    & .icon {
        background-color: var(--color-bg-positive);
    }
}

.icon {
    box-shadow: inset 0 0 0 1px var(--color-border-special-component);
    display: flex;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
}

.icon svg {
    width: 22px;
    height: 22px;
    color: var(--color-graphic-static);
}

.compact .icon svg {
    width: 16px;
    height: 16px;
}

.icon, .leftAddon {
    margin-right: 12px;
    width: 34px;
    height: 34px;
    min-width: 34px;
    min-height: 34px;
}

.compact .icon, .compact .leftAddon {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
}

.content_text {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.compact .content_text {
    flex-direction: row;
    gap: 12px;
    align-items: center;
}

.content_text > * {
    font-weight: 600 !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
