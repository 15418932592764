.component {
    position: relative;
    flex: 1;
    background: var(--color-bg-primary);
}

.content {
    position: relative;
}

.scroll {
    display: flex;
    width: 100%;
}

.loading {
    opacity: 0.5;
}

.divider {
    top: 22px;
    left: 55px;
    right: 10px;
    height: 8px;
    opacity: 0.2;
    position: absolute;
    border-radius: 6px;
    background: repeating-linear-gradient(
        135deg,
        var(--color-bg-secondary),
        var(--color-bg-secondary) 10px,
        var(--color-graphic-secondary-inverted) 10px,
        var(--color-graphic-secondary-inverted) 20px
    );
}

.addButton {
    position: absolute;
    width: 100px;
    height: 100px;
    background: red;
}

.emptyState {
    position: absolute;
    left: 50%;
    margin-left: -200px;
    top: 50%;
    margin-top: -150px;
}

.horizontalScroll {
    height: 100%;
    overflow: auto;
}

.row {
    height: 48px;
    position: relative;
    box-sizing: border-box;
    border-top: 1px solid var(--color-border-thirdly);
}

.compact .row {
    height: 36px;
}

.row:last-child {
    height: 72px;
}

.compact .row:last-child {
    height: 36px;
}

.row span {
    display: block;
    position: sticky;
    left: 0px;
    width: 47px;
    padding-top: 12px;
    margin-top: -24px;
    height: 150%;
    text-align: center;
    z-index: 4;
    border-right: 1px solid var(--color-border-thirdly);
    background-color: var(--color-bg-special-super-light) !important;
}

.minuteSlot {
    font-size: 10px;
    opacity: 0;
    position: absolute;
    left: 0;
    width: 100%;
    cursor: pointer;
    height: 3px;
    z-index: 1;
    text-align: center;
    background: #2374ef;
    line-height: 40px;
    font-weight: 500;
    border-radius: 2px;
}

.minuteSlot:after {
    content: '+';
    position: absolute;
    display: block;
    left: 50%;
    margin-left: -8px;
    color: var(--color-text-primary-inverted);
    font-weight: 700;
    line-height: 16px;
    text-align: center;
    width: 16px;
    height: 16px;
    top: -6px;
    background: #2374ef;
    border-radius: 50%;
}

.minuteSlot:hover {
    opacity: 1;
}

.table {
    border: 1px solid var(--color-border-thirdly);
    background-color: var(--color-bg-special-super-light) !important;
    border-radius: 12px;
    position: absolute;
    width: 100%;
    max-height: 100%;
    height: 100%;
}

.collapse {
    position: absolute !important;
    top: 54px;
    z-index: 7;
    left: 54px;
    background-color: var(--color-bg-primary) !important;
    width: 48px;
    color: #78828b !important;
    & svg {
        display: block;
    }
}

.clock {
    margin-bottom: 24px;
    position: sticky;
    display: flex;
    z-index: 7;
    left: 0px;
    top: 0px;
    width: 48px;
    height: 48px;
    align-items: center;
    justify-content: center;
    color: var(--color-graphic-thirdly);
    background-color: var(--color-bg-special-big-objects);
}

.slot {
    position: absolute;
    padding: 0 4px;
    z-index: 2;
    box-sizing: border-box;
    width: 100%;
    left: 0;
}

.slot:before {
    content: '';
    top: -16px;
    position: absolute;
    left: 0;
    z-index: 0;
    width: 100%;
    bottom: -16px;
}

.recource {
    width: 100%;
    min-width: 180px;
    display: flex;
    justify-content: center;
    position: relative;
    outline: none;
    border-right: 1px solid var(--color-border-thirdly);
}

@media print {
    .recource {
        min-width: 100px;
    }
} 

.compact .recource {
    min-width: 20px;
}

.resources {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 48px;
    right: 0;
    height: 100%;
    overflow-y: visible;
    display: flex;
}

.header {
    height: 48px;
    background-color: var(--color-bg-special-big-objects);
    position: sticky;
    top: 0;
    z-index: 6;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
