
.image {
    position: relative;
    z-index: 1;

    &:after {
        content: '';
        box-shadow: inset 0 0 0px 1px #ffffff57 !important;
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        z-index: 2;
    }
}

.button {
    margin-left: -12px !important;
}

.container {
    padding-left: 12px !important;
}

.active {
    z-index: 2;

    &:after {
        box-shadow: inset 0 0 0 4px var(--color-bg-main) !important;
    }
}

.title {
    padding-left: 16px;
}
