.container {
    padding: 12px !important;
    border-radius: 8px !important;
    text-align: center;
    max-width: 400px;
    margin: 0 auto;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 12px;
    justify-content: center;
}

.fullView {
    width: 100%;
    height: 100%;
}

.children {
    margin-top: 20px;
}