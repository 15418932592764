.image_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 8px;
    width: 100% !important;
    height: 100% !important;
    transition: all 0.1s ease-in-out;
}

/* .disabled {
    transform: none !important;
    cursor: default !important;
    z-index: 10;
}

.disabled button {
    cursor: default !important;
} */


.image * {
    background-color: transparent !important;
    color: #fff !important;
}
