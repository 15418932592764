.container {
    text-align: left;
    width: 100%;
}

.leftAddons {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.text {
    padding-top: 2px;
    padding-left: 4px;
}

.container span {
    text-align: left;
}