
  .component {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .loader {
    border-radius: 80%;
    display: block;
    height: 50px;
    width: 50px;
    position: relative;
    -webkit-animation: spin 0.675s linear 0s infinite normal;
            animation: spin 0.675s linear 0s infinite normal;
    background: var(--color-bg-primary);
  }
  .loader:before, .loader:after {
    content: "";
    display: block;
    position: absolute;
  }
  .loader:before {
    border-radius: 0 90px 90px 0;
    height: 50px;
    width: 50%;
    top: 0;
    right: 0;
    z-index: 1;
    background: var(--color-text-accent);
    background-image: linear-gradient(var(--color-text-accent),  var(--color-bg-primary));
  }
  .loader:after {
    border-radius: 80%;
    height: 40px;
    width: 40px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    background: var(--color-bg-primary);
  }
  
  @-webkit-keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }