body,
html {
    margin: 0;
    padding: 0;
    height: 100%;
    min-height: 100%;
    color: var(--color-text-primary);
    /* overflow: hidden; */
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--color-bg-primary);
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
}


p {
    margin-top: 0;
    margin-bottom: 4px;
}

/* * {
    box-sizing: border-box;
} */

.sticky {
    position: sticky;
    top: 0
}

#root {
    height: 100%;
    overflow: auto;
}

.brandText {
    background: linear-gradient(90deg, #1189fa 0%, #b65ef7 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.brandBg, .warningBg, .errorBg {
    height: 40px !important;
    min-height: 40px !important;
    border-radius: 12px !important;
    border: 0 !important;
    color: #fff !important;
    box-sizing: border-box !important;
}

.brandBg {
    background: linear-gradient(90deg, #1189fa 0%, #b65ef7 100%);
}

.warningBg {
    background: linear-gradient(90deg, #faac11 0%, #e8b02e 100%);
}

.errorBg {
    background: linear-gradient(90deg, #c91919 0%, #f7a05e 100%);
}


.App {
    height: 100%;
}

.quill {
    border: 1px solid var(--color-border-primary);
    box-sizing: border-box;
    border-radius: 8px;
    margin-bottom: 16px;
    flex: 1;
}

.quill .ql-toolbar {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    border: none;
    border-bottom: 1px solid var(--color-border-primary);
}

.quill .ql-toolbar > div {
    display: flex;
    align-items: center;
}

.quill .ql-toolbar > div > div {
    margin-right: 3px;
}

.quill .ql-container {
    padding: 8px 16px;
    display: flex;
    height: 100%;
    overflow: auto;
    border: none;
}

.quill .ql-container .ql-editor {
    width: 100%;
}

#toast_manager {
    z-index: 999999999;
}

table tr:last-child td {
    border-bottom: 0 !important;
}

.onlyForPrint {
    display: none;
} 

@media print {
    .noPrint {
        display: none !important;
    }
    .onlyForPrint {
        display: block;
    }
    body {
        zoom: 0.7;
    }
    body, html {
        height: auto;
        min-height: auto;
    }
}

pre {
    background: var(--color-bg-special-big-objects);
    border-left: 3px solid var(--color-bg-positive);
    border-radius: 0 8px 8px 0;
    color: var(--color-text-primary);
    font-size: 14px;
    line-height: 18px;
    margin: 0;
    max-width: 100%;
    overflow: auto;
    padding: 1em 1.5em;
    display: block;
    word-wrap: break-word;
    box-sizing: border-box;
}