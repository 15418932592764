.history_table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 8px;


    & td:last-child {
        padding-right: 0;
        width: 100px;
        text-align: end;
    }

    & td:first-child {
        padding-left: 0;
        text-align: start;

    }

    & td {
        border-bottom: 1px solid var(--color-border-primary);
    }
}
