.container {
    position: relative;
    max-width: 1920px;
    height: 100%;
}

.content {
    margin: 0 48px 0 310px;
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.header {
    position: relative;
    z-index: 999;
    position: sticky;
    top: 0;
    height: 40px;
    padding: 20px 0 16px;
    display: flex;
    flex: none;
    align-items: center;
    justify-content: space-between;
    background-color: var(--color-bg-primary);
}

.menuLoader {
    height: 100%;
    width: 100%;
    min-height: 500px;
}

.body {
    padding-bottom: 24px;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.menu {
    position: fixed;
    display: flex;
    flex-direction: column;
    left: 24px;
    width: 248px;
    top: 0;
    bottom: 16px;
}

.menuItems, .menuCalendar, .companies {
    background-color: var(--color-bg-special-big-objects);
    border-radius: 12px;
}

.menuCalendar {
    padding: 4px 12px;
    background-color: var(--color-bg-special-big-objects);
    margin-bottom: 16px;
    flex: none;
    position: relative;
}

.companies {
    padding: 12px 24px;
    margin-bottom: 16px;
}

.menuItems {
    padding: 16px 12px;
    position: relative;
    overflow: auto;
    flex: 1;
    gap: 16px;
    display: flex;
    flex-direction: column;
    align-items: start;
}

.logo {
    display: flex;
    gap: 10px;
    align-items: center;
    line-height: 20px;
    flex: none;
    font-size: 16px;
    font-weight: bold;
    height: 40px;
    padding: 20px 20px 16px;
    text-decoration: none;
    color: var(--color-text-primary);
}

@media print {
    .menu, .header {
      display: none !important;
    }
    .content {
        margin: 0 !important;
    }
  }